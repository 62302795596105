@font-face {
    font-family: 'Quicksand';
    src: url('./assets/fonts/Quicksand-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 700; /* Range of weights */
    font-style: normal;
  }

* {
    margin: 0;
    padding: 0;
    font-family: 'Quicksand';
    font-weight: 500;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.link-text-reset {
    text-decoration: none;
    color: #000;
}

#root {
    height: 100vh;
}

.navbar-offset {
    padding-top: 101px;
}

.logo.navbar-brand a {
    font-size: 2.5em;
    font-weight: bold;
    text-decoration: none;
    color: #ff7967;
}

i.fa-up-right-from-square {
    padding: 0 0 0 3px;
}

.collapse {
    float: right;
}

.footer {
    background-color: #ff7967;
    color: #fff;
    text-align: center;
    width: 100%;
    font-weight: 600;
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-view {
    background: linear-gradient(180deg, #177d8b 0%, #87acb3 55%, #fff 100%);
    height: 100vh;
}


.landing-photo {
    width: 50vh;
    max-width: 600px;
    max-height: 50vh;
    float: right;
    border: 10px #fff solid;
    border-radius: 100%;
}

.hero-header-font {
    font-size: 8rem;
    width: 100%;
    display: inline-block;
    text-align: left;
    color: #fff;
    font-weight: 600;
    line-height: 90%;
}

.text-justify {
    text-align: justify;
    text-justify: inter-word;
}

.intro-container {
    min-height: 40vh;
}

.intro-row {
    padding: 50px 5% 0;
}

.intro-col p {
    font-size: 1.2rem;
}

.intro-h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #ff7967;
}

.intro-h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
}

.intro-img-container {
    position: relative;
    display: inline-block;
}

.intro-img {
    display: block;
    width: 100%;
    height: auto;
    padding: 5px 0;
    object-fit: fill;
    border-radius: 15px;
}

.intro-img-overlay {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: end;
    background-color: #00000069;
}

.fa-solid, .fa-brands {
    padding: 0 10px;
}

.service-container {
    min-height: 40vh;
}

.service-row {
    padding: 0 15%
}

.service-col {
    /* position: relative;
    display: inline-block; */
    background-color: #57aec0;
    transition: 0.3s ease;
    border-radius: 5px;
    box-shadow: 2px 3px 4px #00000084;
    color: #fff;
    font-size: 2rem;
    
}

.service-col:hover {
    background-color: #daa70089;
    color: #57aec0;
    transform: scale(1.03);
    box-shadow: 0 6px 8px #00000026;
}

.service-col:active {
    transform: scale(0.98);
    box-shadow: 0 2px 4px #0000001A;
}

.service-list {
    /* color: #ffd900; */
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #177d8b;
    border-radius: 0 0 5px 5px;
    padding: 5px;
}

.service-min-height {
    min-height: 100px;
}

.service-h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #ff7967;
}

.service-h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
}

.service-pg-h1 {
    font-size: 3rem;
    font-weight: 700;
    color: #ff7967;
}

.service-pg-h2 {
    font-size: 2rem;
    font-weight: 700;
    /* color: #177d8b; */
    color: #ffe555;
    padding: 0 35px;
}

.age-readout {
    background-color: #177d8b;
    color: #fff;
    font-weight: 500;
}

.age-readout-h1 {
    color: #ff7967;
    font-size: 4rem;
}

.clock-font {
    font-size: 2rem;
}

.about-img {
    width: 100%;
}

.service-img {
    margin-top: 10px;
}

.service-pg-img {
    width: 100%;
    padding: 8px;
}

.service-banner {
    background-color: #61b0c4;
    border-radius: 5px 5px 0 0;
}

@media (max-width: 1440px) {
    .service-list {
        font-size: 1rem;
    }

    .service-pg-h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 1024px) {
    /* .hero-view {
        padding-top: 10vh;
        height: 80vh;
    } */

    .hero-header-font {
        font-size: 5rem;
    }

    .landing-photo {
        max-width: 100%;
    }

    .clock-font {
        font-size: 1.5rem;
    }

    .service-img {
        margin-bottom: 15px;
    }

    .service-h1 {
        font-size: 4rem;
    }

    .service-h2 {
        font-size: 1.25rem;
    }

    .service-list {
        font-size: 1rem;
    }

    .intro-col p {
        font-size: 1rem;
    }
}

@media (max-width: 991px) {
    .landing-photo {
        float:none;
        max-width: 90%;
        margin-bottom: 15px;
    }

    .landing-photo-div {
        display: flex;
        justify-content: center;
    }

    .hero-header-font {
        text-align: center;
        font-size: 5rem;
    }

    .intro-h1 {
        font-size: 3.5rem;
        font-weight: 700;
    }

    .intro-row {
        padding-top: 15px;
    }

    .age-readout-h1 {
        color: #ff7967;
        font-size: 3rem;
    }

    .clock-font {
        font-size: .85rem;
    }

    .service-col {
        font-size: 2rem;
    }

    .service-row {
        padding: 0
    }
}

@media (max-width: 768px) {
    .logo.navbar-brand a {
        font-size: 2rem;
    }

    .hero-header-font {
        font-size: 2rem;
    }
    
    .mobile-pt-2 {
        padding-top: 10px;
     }

    .intro-h1 {
        font-size: 4rem;
        font-weight: 700;
    }

    .service-link {
        width: 50%;
    }

    .service-banner {
        text-align: start;
    }

    .service-pg-h2 {
        font-size: 1.5rem;
    }

     /* .mobile-mb-3 {
        margin-bottom: 15px;
     } */
     .service-h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 490px) {
    .landing-photo {
        max-width: 70%;
    }

    .service-h2 {
        font-size: 1.25rem;
    }
}

@media (max-width: 425px) {
    .intro-col p {
        font-size: 1rem;
    }

    a.service-link {
        max-width: 50%;
    }

    .service-h2 {
        font-size: 1.25rem;
    }

    .service-pg-h2 {
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
    }

    .service-pg-img {
        width: 60%;
    }

    .service-banner {
        flex-direction: column;
        padding-top: 15px;
    }
}

@media (max-width: 375px) {
    .service-pg-h2 {
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
    }
}

@media (max-width: 320px) {
    .service-pg-h1 {
        font-size: 2rem;
    }
    .service-pg-h2 {
        font-size: 1.25rem;
        font-weight: 700;
        text-align: center;
    }
    .service-h2 {
        font-size: 1rem;
    }
}